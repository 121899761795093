import http from "../../http";

const orderCenter = {
    selectOrderTenantPage(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/selectOrderTenantPage", r)
    },
    selectOrderCompanyPage(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/selectOrderCompanyPage", r)
    },
    selectOrderCenterInfoByNo(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/selectOrderCenterInfoByNo", r)
    },
    getShippingCancelEnums(r) {
        return http.get("/gateway/api/sycompany/pc/ShippingOrderPC/getShippingCancelEnums", r)
    },
    shippingPreOrderCenter(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/shippingPreOrderCenter", r)
    },
    shippingOrderCenter(r) {
        return http.confirm.payload("/gateway/api/sycompany/pc/OrderCenterPC/shippingOrderCenter", r)
    },
    shippingOrderCenterCancel(r) {
        return http.payload("/gateway/api/sycompany/pc/OrderCenterPC/shippingOrderCenterCancel", r)
    },
    selectByOrderCenterMergeNo(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/selectByOrderCenterMergeNo", r)
    },
    cancelOrder(r) {
        return http.confirm.payload("/gateway/api/sycompany/pc/OrderCenterPC/cancelOrder", r)
    },
    selectMergeTenantPage(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/selectMergeTenantPage", r)
    },
    selectRefundTenantPage(r) {
        return http.get("/gateway/api/sycompany/pc/orderCenterRefund/selectRefundTenantPage", r)
    },
    selectRefundInfo(r) {
        return http.get("/gateway/api/sycompany/pc/orderCenterRefund/selectRefundInfo", r)
    },
    getCompanyOrderCount(r) {
        return http.get("/gateway/api/sycompany/pc/OrderCenterPC/getCompanyOrderCount", r)
    },
    confirmVerificationSp(r) {
        return http.confirm.payload("/gateway/api/sycompany/pc/OrderCenterPC/confirmVerificationSp", r)
    },
    changePickup(r) {
        return http.confirm.payload("/gateway/api/sycompany/pc/OrderCenterPC/changePickup", r)
    },
    selectOrderStatisticsTenantPage(r) {
        return http.get("/gateway/api/sycompany/pc/CompanyAmountDaily/selectTenantPage", r)
    },
    queryByRecordPage(r) {
        return http.get("/gateway/api/sycompany/pc/CompanyReceiptApi/queryByRecordPage", r)
    },
    printOrder(r) {
        return http.confirm.get("/gateway/api/sycompany/pc/OrderCenterPC/printOrder", r)
    },
    cashPay(scene,r) {
        return http.confirm.payload(`/gateway/api/sycompany/pc/CompanyOrderPayCenterPC/${scene}/cashPay`, r)
    },

}
export default orderCenter
