

import store from '@/store'
const hasPermi = (el, binding) => {
    let roleCode = window.sessionStorage.getItem('roleCodes');
    if (roleCode == null || roleCode == undefined) {
        roleCode = ''
    }
    let roles = roleCode.split(',');
    const { value } = binding

    if (value == undefined || value == null) {
        return true;
    }

    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value

            const hasPermission = roles.some(role => {
                return permissionRoles.includes(role)
            })

            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        }
    } else {
      //  el.parentNode && el.parentNode.removeChild(el)
    }
}

export default hasPermi;