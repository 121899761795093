import http from "../../http";

const device = {
    getCompanyDeviceList(r) {
        return http.get("/gateway/api/sycompany/pc/CompanyDevice/getCompanyDeviceList", r)
    },
    createDevice(r) {
        return http.payload("/gateway/api/sycompany/pc/CompanyDevice/createDevice", r)
    },
    updateDevice(r) {
        return http.payload("/gateway/api/sycompany/pc/CompanyDevice/updateDevice", r)
    },
    deleteDevice(r) {
        return http.confirm.post("/gateway/api/sycompany/pc/CompanyDevice/deleteDevice", r)
    },
    getCompanyDevice(r) {
        return http.get("/gateway/api/sycompany/pc/CompanyDevice/getCompanyDevice", r)
    },
}
export default device
