import http from "../../http";

const index = {

    searchCompanyList(r) {
        return http.get("/gateway/api/sycompany/pc/company/listCompanyOption", r)
    },
    getUserJson(r) {
        return http.get("/gateway/api/sycompany/pc/company/getUserJson", r)
    },
    getCompanyByOrgCode(r) {
        return http.get("/gateway/api/sycompany/pc/company/getCompanyByOrgCode", r)
    },



}
export default index
