import http from "../../http";

const order = {
    selectPage(r) {
        return http.get("/gateway/api/sycompany/pc/companyTable/selectPage", r)
    },
    selectById(r) {
        return http.get("/gateway/api/sycompany/pc/companyTable/selectById", r)
    },
    addTable(r) {
        return http.payload("/gateway/api/sycompany/pc/companyTable/addTable", r)
    },
    batchAddTable(r) {
        return http.payload("/gateway/api/sycompany/pc/companyTable/batchAddTable", r)
    },
    updateTable(r) {
        return http.payload("/gateway/api/sycompany/pc/companyTable/updateTable", r)
    },
    deleteTable(r) {
        return http.confirm.post("/gateway/api/sycompany/pc/companyTable/deleteTable", r)
    },
    batchDeleteTable(r) {
        return http.confirm.payload("/gateway/api/sycompany/pc/companyTable/batchDeleteTable", r)
    },
}
export default order
