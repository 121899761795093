import http from "../../http";

const config = {
    addBaseConfig(r) {
        return http.payload("/gateway/api/sycompany/pc/CompanyBaseConfigPC/addBaseConfig", r)
    },
    updateBaseConfig(r) {
        return http.payload("/gateway/api/sycompany/pc/CompanyBaseConfigPC/updateBaseConfig", r)
    },
    selectBaseConfigByCompanyId(r) {
        return http.get("/gateway/api/sycompany/pc/CompanyBaseConfigPC/selectBaseConfigByCompanyId", r)
    },
}
export default config
