import http from "../../http";

const order = {
    printOrder(r) {
        return http.confirm.post("/gateway/apps/o2o/api/o2o/order/printOrder", r)
    },
    getSupplierCategoryByList(r) {
        return http.get("/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryByList", r)
    },
    agreeRefundOrder(r) {
        return http.confirm.post("/gateway/apps/o2o/api/o2o/admin/agreeRefundOrder", r)
    },
    rejectBackOrder(r) {
        return http.post("/gateway/apps/o2o/api/o2o/admin/rejectBackOrder", r)
    },
    refund(r) {
        return http.confirm.post("/gateway/apps/o2o/api/o2o/admin/refund", r)
    },
}
export default order
